import * as React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/SEO'
import LandingPage from '../components/LandingPage'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <LandingPage />
  </Layout>
)

export default IndexPage
