import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

const Container = styled.div`
  text-align: center;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 78vh;
`

const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

const Disclaimer = styled.p`
  font-size: 0.7rem;
`

const NameHeader = styled.h1`
  font-size: 5rem;
  font-weight: 100%;
  margin-bottom: 0;
`

const LandingPage = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
            disclaimer
          }
        }
      }
    `}
    render={data => (
      <OuterContainer>
        <Container>
          <NameHeader>{data.site.siteMetadata.title}</NameHeader>
          <Description>{data.site.siteMetadata.subtitle}</Description>
          <Disclaimer>{data.site.siteMetadata.disclaimer}</Disclaimer>
        </Container>
      </OuterContainer>
    )}
  />
)

export default LandingPage
